/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.error-alert {
    color: red;
}

.container {
    padding-bottom: 10px;
}

.save-alert {
    color: green;
    padding-top: 10px;
}

button:disabled {
    cursor: not-allowed;
}

.float-right {
    float: right;
}

.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.search-box {
    margin-bottom: 20px;
    background-color: lightgray;
    padding: 10px;
    border-radius: 5px;
}

.field-note {
    font-size: 0.8em;
    color: gray;
}

.no-bottom-margin {
    margin-bottom: 0;
}

.button-group {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.details-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
}

.details-box {
    background-color: lightgray;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.details-box h2 {
    font-size: 1.25rem;
    margin-bottom: 0;
}

.paging {
    display: flex;
    justify-content: center;
    height: 35px;
}

.paging span {
    text-align: center;
}

.paging div {
    display: flex;
    align-items: center;
}

.paging-button-container {
    width: 40px;
}

.paging-button-container button {
    font-size: 2em;
    padding: 0;
}

.paging div:nth-of-type(2) {
    justify-content: center;
}

.paging div:nth-of-type(3) {
    justify-content: flex-end;
}

.buttons-column {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
}

.table {
    margin-top: 1rem;
}

.table th, .table td {
    padding: 0.25rem;
    vertical-align: middle;
}

.table .btn {
    padding: 0.1rem;
}

.number-column {
    text-align: right;
}

.page-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 5px;
}

.page-heading :nth-child(1),
.page-heading :nth-child(3) {
    flex-grow: 1;
    flex-basis: 0;
}

.page-heading h1 {
    margin-bottom: 0;
}

input[type=checkbox] {
    margin-right: 5px;
}

.sort-button {
    display: inline-flex;
    flex-direction: column;
    padding: 0;
    justify-content: center;
    height: 100%;
    margin-left: 5px;
}

.sort-button i {
    height: 0.7rem;
    position: relative;
    top: -7px;
}

.search-buttons {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.results-loading {
    opacity: 0.3;
}

.loading-spinner-container.on-table {
    position: relative;
    height: 0;
    top: 100px;
}

.report-link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input[type="radio"] {
    margin-right: 5px;
}

input[type="radio"]:nth-of-type(n+2){
    margin-left:20px;
}

.force-wrap {
    width: 100%;
}

.navbar-nav {
    flex-wrap: wrap;
    justify-content: flex-end;
}